<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:15px !important;`">
            <v-col cols="12" md="12" sm="12">
                <form autocomplete="off">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.start_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;" v-model="sd.day" :options="daysList"/>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;" v-model="sd.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.end_date}}</label>
                        <b-input-group>
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;" v-model="ed.day" :options="daysList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#fefefe;" v-model="ed.year" :options="yearsList" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-input-group>
                        <b-form-input class="inborder" v-model="mobile" style="background:#fefefe;" />
                        </b-input-group>
                    </v-col>

                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.voutcher_no}}</label>
                        <b-input-group>
                        <b-form-input class="inborder" v-model="voutcherid" style="background:#fefefe;" />
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label></label>
                        <b-input-group>
                        <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getVouchersx()">{{lang.search}}</b-button>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" class="text-end pt-4 pe-10" style="padding-inline-end:30px;">

                        <b-button
                            v-b-toggle.add_inreceipt  @click="resetAll()"
                            class="btn-sm btn"
                            style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
                            id="multiply-button" 
                            >
                            <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add}}</div>
                        </b-button>
                    </v-col>
                </v-row>
                </form>
            </v-col>
        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:5px !important;`">
            <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="inrows"
                        disable-pagination
                        hide-default-footer
                        class="elevation-1"
                        style="padding-inline-end:30px;"
                    >
                        <template v-slot:item="row">
                        <tr>
                            <td class="text-center ">{{ row.item.voutcherid }}</td>
                            <td class="text-center ">{{ row.item.received_mobile }}</td>
                            <td class="text-center ">{{ row.item.received_from }}</td>
                            <td class="text-center ">{{ row.item.received_by }}</td>
                            <td class="text-center ">{{ row.item.receipt_date.substr(0,10) }}</td>
                            <td class="text-center ">{{ $RoundNums(row.item.total,2) }}</td>
                            <td class="text-center ">{{ $RoundNums(row.item.vat,2) }}</td>
                            <td class="text-center ">{{ $RoundNums(row.item.ftotal,2) }}</td>
                            <td class="text-center ">{{ $RoundNums(row.item.paycash,2) }}</td>
                            <td class="text-center ">{{ $RoundNums(row.item.payspan,2) }}</td>
                            <td class="text-center " style="width:100px;background:blue !important;color:#FFF;border:2px solid #fff !important;">
                                <v-btn style="box-shadow:none;font-size:0.2em;background:blue;width:100px;color:#FFF;height:25px !important;" @click="printPDF(row.item.id)">{{lang.print_voucher}}<i class="fas fa-caret-left" style="display:none"></i></v-btn>
                            </td>
                        </tr>
                        </template>
                    </v-data-table>
                </template>
            </v-col>
            <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
                <v-row :style="`direction:`+lang.dir+`;margin-top:-10px;margin-inline-end:20px`">
                    <v-col cols="12" md="3" sm="6">
                        <b-table-simple :style="`margin-inline-start:30px;direction:`+lang.dir">
                            <thead>
                                <!-- <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.total}}</th>
                                    <td :style="`background:lightgreen;text-align:`+lang.algin+`;`">{{ $RoundNums(all_total,2) }}</td>
                                </tr>
                                <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.vat}}</th>
                                    <td :style="`background:lightgreen;text-align:`+lang.algin+`;`">{{ $RoundNums(all_vat,2) }}</td>
                                </tr> -->
                                <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.ftotal}}</th>
                                    <td :style="`text-align:`+lang.algin+`;`">{{ $RoundNums(all_ftotal,2) }}</td>
                                </tr>
                                <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.cash_total}}</th>
                                    <td :style="`text-align:`+lang.algin+`;`">{{ $RoundNums(all_cash,2) }}</td>
                                </tr>
                                <tr>
                                    <th class="backBlack" :style="`text-align:`+lang.lalgin+`;`">{{lang.span_total}}</th>
                                    <td :style="`text-align:`+lang.algin+`;`">{{ $RoundNums(all_span,2) }}</td>
                                </tr>
                            </thead>
                        </b-table-simple>
                    </v-col>
                    
                </v-row>
            </v-col>
        </v-row>

        <inReceipt ref="inReceipt" />
    </div>
        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
            <v-progress-circular
                :width="3"
                color="green"
                indeterminate
            ></v-progress-circular>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import inReceipt from '../components/inReceipt.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ExportsPrint from '@/components/exports-print.vue';
export default{
    components:{
        inReceipt,
        BreadCrumbs,
        TabsComp,
        HeaderPortrate,
        Footer,
        ExportsPrint,
    },
    data() {
        return {
            // sdate: '',
            // todate: '',
            all_total: 0,
            all_cash: 0,
            all_span: 0,
            all_ftotal: 0,
            all_vat: 0,
            excel_fileds: {
                'رقم السند': 'voutcherid',
                'الاستلام من': 'received_from',
                'تاريخ السند': 'receipt_date',
                'المستلم': 'received_by',
                'المبلغ الاجمالي': 'total',
                'قيمة الضريبة 15%': 'vat',
                'الاجمالي مع الضريبة': 'ftotal',
                'شبكة': 'payspan',
            },
            mobile: '',
            voutcherid: '',
            inrows: [
                
            ],
            active_tab:0,
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
            
        }
    },
    methods: {
        resetAll(){
            this.$refs.InReceipt.resetAll();
        },
        collectSearch(){
            return {
            module: 'outrecipt',
                elements: {
                    sdate: this.sdate,
                    edate: this.edate,
                    type: 1
                }
            }
        },
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?recpid='+id;
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        getVouchersx(){
            const post = new FormData();
            post.append("type" , 'getVoucher');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append('data[sdate]',this.sdate);
            post.append('data[todate]',this.edate);
            post.append('data[mobile]',this.mobile);
            post.append('data[voutcherid]',this.voutcherid);
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    this.$store.state.showLoading = false
                    let all_total = 0;
                    let all_ftotal = 0;
                    let all_vat = 0;
                    let all_cash = 0;
                    let all_span = 0;
                    const res = response.data;
                    if(res.results.data != false){
                        this.inrows = res.results.data;
                        if(res.results.data.length != 0){
                            for(let i = 0;i<res.results.data.length ; i++){
                                all_total = +parseFloat(all_total) + +parseFloat(res.results.data[i].total);
                                all_ftotal = +parseFloat(all_ftotal) + +parseFloat(res.results.data[i].ftotal);
                                all_vat = +parseFloat(all_vat) + +parseFloat(res.results.data[i].vat);
                                all_cash = +parseFloat(all_cash) + +parseFloat(res.results.data[i].paycash);
                                all_span = +parseFloat(all_span) + +parseFloat(res.results.data[i].payspan);
                            }
                        }
                    }
                    
                    this.all_total = this.$RoundNum(all_total);
                    this.all_ftotal = this.$RoundNum(all_ftotal);
                    this.all_vat = this.$RoundNum(all_vat);
                    this.all_cash = this.$RoundNum(all_cash);
                    this.all_span = this.$RoundNum(all_span);
                }
            )
        },
        preparedata(){
            let exdata = {
                list: "inReceList",
                title: this.lang.received_voucher,
                data: [],
            }
            for(let i=0;i<this.inrows.length;i++){
                exdata.data.push(this.inrows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.sdate;
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
         
        },
        
    },
    created() {
        // this.getStartDate();
        this.getVouchersx();
        // setInterval(
        //     () => {this.getVouchersx()} , 10000
        // )
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        todate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
                {text: this.lang.day, value: -1},
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6},
                {text: 7, value: 7},
                {text: 8, value: 8},
                {text: 9, value: 9},
                {text: 10, value: 10},
                {text: 11, value: 11},
                {text: 12, value: 12},
                {text: 13, value: 13},
                {text: 14, value: 14},
                {text: 15, value: 15},
                {text: 16, value: 16},
                {text: 17, value: 17},
                {text: 18, value: 18},
                {text: 19, value: 19},
                {text: 20, value: 20},
                {text: 21, value: 21},
                {text: 22, value: 22},
                {text: 23, value: 23},
                {text: 24, value: 24},
                {text: 25, value: 25},
                {text: 26, value: 26},
                {text: 27, value: 27},
                {text: 28, value: 28},
                {text: 29, value: 29},
                {text: 30, value: 30},
                {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
                {text: this.lang.chose_month, value: -1},
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6},
                {text: 7, value: 7},
                {text: 8, value: 8},
                {text: 9, value: 9},
                {text: 10, value: 10},
                {text: 11, value: 11},
                {text: 12, value: 12},
            ]
        },
        headers: function () {
            return [
                {
                    text: this.lang.voutcher_no,
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    class: 'backBlack'
                },
                { text: this.lang.mobile, value: 'received_mobile',sortable: false, class: 'backBlack' },
                { text: this.lang.receive_from, value: 'received_from',sortable: false, class: 'backBlack' },
                { text: this.lang.receive_by, value: 'received_by',sortable: false, class: 'backBlack' },
                { text: this.lang.date, value: 'receipt_date',sortable: false, class: 'backBlack' },
                { text: this.lang.total, value: 'total',sortable: false, class: 'backBlack' },
                { text: this.lang.vat + ' 15%', value: 'vat',sortable: false, class: 'backBlack' },
                { text: this.lang.ftotal, value: 'ftotal',sortable: false, class: 'backBlack' },
                { text: this.lang.cash, value: 'paycash',sortable: false, class: 'backBlack' },
                { text: this.lang.span, value: 'payspan',sortable: false, class: 'backBlack' },
                { text: this.lang.action,class: 'backBlack' },
            ]
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.received_voucher,
                    disabled: true,
                    to: '/finance/inreceipt',
                }
        }
    },
}
</script>
<style>
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>